/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      imageSharp(fluid: {originalName: {eq: "KlaviernotenBg.jpg"}}) {
        id
        fluid {
          src
          tracedSVG
        }
      }
    }
  `)

  const bgImage = data.imageSharp.fluid.src
  const path = globalHistory.location.pathname
  // const pagePath = data.sitePage.path
  // console.log(path)

  return (
    <div 
      className="content-wrap"
    >
      <div className="background-container">
        <div
          className="background"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        />
      </div>
      <main
        className={path === "/" ? "main frontpage" : "main"}
      >
        {children}
      </main>
      <footer
        className="page-footer"
      >
        © {new Date().getFullYear()}
        {` | `}
        <a href="https://17hippies.de">17 Hippies</a>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
