import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>Ups, du hast dich verlaufen!</h1>
    <p>Solltest du durch einen Link oder Barcode in einem unserer Notenbücher hier gelandet sein, schicke doch bitte eine kurze Nachricht an uns, damit wir den Fehler beheben können.</p>
    <p>Hilfreiche Informationen für uns sind, ob du einen Barcode-Link benutzt hast, welche Seite du erreichen wolltest und welche URL in dem Adressfeld des Browsers zu sehen ist.</p>
    <p>Dazu einfach eine kurze Mail schicken an <a href="mailto:info@17hippies.de?subject=404-Fehler%20auf%20realbook.17hippies.de">info@17hippies.de</a>. Vielen Dank!</p>
    <p><Link to="/">{"<••• zurück zur Übersicht"}</Link></p>
  </Layout>
)

export default NotFoundPage
